/* eslint-disable react/style-prop-object */
import React from "react";
import Header from "./Header";
import "./Contact.scss";
import ContactFunFactory from "./ContactFunFactory";
import ContactGeske from "./ContactGeske";
import {useCompany} from "../context/CompanyContext";

const headerLeftContent = (
  <>
    <h1>Contact</h1>
  </>
);

const Contact = () => {
const {selectedCompany} = useCompany();
  return (
    <div className="">
      <Header leftContent={headerLeftContent} />

        {selectedCompany.id === 'fnf' ? <ContactFunFactory/> : <ContactGeske/>}

    </div>
  );
};

export default Contact;
