/* eslint-disable react/style-prop-object */
import React from "react";
import Header from "./Header";
import "./Privacy.scss";
import {useCompany} from "../context/CompanyContext";
import PrivacyFunFactory from "./PrivacyFunFactory";
import PrivacyGeske from "./PrivacyGeske";

const headerLeftContent = (
  <>
    <h1>Privacy Policy</h1>
  </>
);

const Privacy = () => {

const {selectedCompany} = useCompany();

  return (
    <div>
      <Header leftContent={headerLeftContent} />


        {selectedCompany.id === 'fnf' ? <PrivacyFunFactory/> : <PrivacyGeske/>}

</div>
  );
};

export default Privacy;
