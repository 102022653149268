import {Company} from "../types/company";

export const COMPANIES: Array<Company> = [
    {
        name: 'GESKE',
        id: 'gsk',
        hostnames: ['*.geske.com', 'nt3cxtillh8kfws6gcrplw.dev.awesomeprojects.de'],
        logoSrc: '/static/img/geske-logo-black.svg',
        email: 'sales@geske.com',
        assetDir: 'geske'
    },
    {
        name: 'Fun Factory',
        id: 'fnf',
        hostnames: ['*.funfactory.com', 'wyybp9e2fbhzrzu2zgfnlg.dev.awesomeprojects.de', 'hs0fyxiavvftkgwb24nqpq.prod.awesomeprojects.de'],
        logoSrc: '/static/img/ff-logo-rgb-germany.svg',
        email: 'sales@funfactory.com',
        assetDir: 'ff'
    }
];

export function getCompanyByHostname(value: string){
    const matchingExactly = COMPANIES.find(c => c.hostnames.includes(value));
    if (matchingExactly) {
        return matchingExactly;
    }
    for (const company of COMPANIES) {
        for (const hostname of company.hostnames) {
            if (hostname.startsWith('*.') && value.endsWith(hostname.substring(2))) {
                return company
            }
        }
    }
    return null
}
