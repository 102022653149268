/* eslint-disable react/style-prop-object */
import React from "react";
import Header from "./Header";
import "./Contact.scss";

const ContactFunFactory = () => {
  return (
      <>
          <div className="container-contact">
              <p>
                  Thank you for your excitement and expressed interest in FunFactory.<br/>
                  <br/>
                  We are committed to being a leader in discovering innovative ways to connect with our beauty partners
                  and consumers around the world, delivering effective solutions at affordable prices with speed and
                  simplicity.<br/>
                  <br/>

                  <b>Contact us at any time: <a href="mailto:info@funfactory.com">info@funfactory.com</a></b>
                  <br/>
                  <br/>
                  Fun Factory Germany GmbH<br/>
                  Dortmunder Straße 34<br/>
                  28199 Bremen<br/>
                  Germany<br/>
                  <br/>
                  and<br/>
                  <br/>
                  Triple A Marketing<br/>
                  Am Lenkwerk 3 33609 Bielefeld<br/>
                  Germany<br/>
                  GESKE Beauty Tech Distribution GmbH<br/>
                  Leipziger Platz 18<br/>
                  10117 Berlin<br/>
                  Germany <br/>
              </p>
          </div>
      </>
  );
};

export default ContactFunFactory;
